import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 100px 0",
	"quarkly-title": "Price-19",
	"background": "#111828"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 700 46px/1.2 --fontFamily-sans",
			"text-align": "center",
			"children": "SERVICES"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 70px 0px",
			"text-align": "center",
			"font": "normal 400 22px/1.5 --fontFamily-sansHelvetica",
			"color": "--lightD1",
			"md-margin": "0px 0px 50px 0px",
			"children": "Horizon Line Yacht Rentals offers a range of services designed to make your time on the sea unforgettable. Our personalized and quality-focused offerings ensure that every moment spent on the water is one of discovery, relaxation, and adventure."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "0 50px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 25px/1.2 --fontFamily-sans",
			"children": "Yacht and Motorboat Fleet"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 0px 0px",
			"font": "--base",
			"color": "--lightD1",
			"children": <>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Elegant Yachts
					<br />
					{" "}
				</Strong>
				Our yachts are perfect for those seeking a blend of luxury and adventure. They offer ideal conditions for serene relaxation or festive gatherings.
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 0px 0px",
			"font": "--base",
			"color": "--lightD1",
			"children": <>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Fast Motorboats
					<br />
				</Strong>
				For the adventurous souls looking to explore the coastline, our motorboats offer speed, maneuverability, and the freedom to discover hidden coves and beaches.
			</>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 25px/1.2 --fontFamily-sans",
			"children": "Personalized Journeys"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 0px 0px",
			"font": "--base",
			"color": "--lightD1",
			"children": <>
				<Strong>
					Customized Adventures:{" "}
				</Strong>
				each journey is as unique as our clients. Whether you seek a peaceful solo expedition or a vibrant water party, we tailor the trip to your desires.
			</>
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 0px 0px",
			"font": "--base",
			"color": "--lightD1",
			"children": <>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Sunset Cruises
					<br />
				</Strong>
				Experience the magic of the horizon at sunset with our specially designed evening cruises, perfect for romantic getaways or solitary reflections.
			</>
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 25px/1.2 --fontFamily-sans",
			"children": "Experienced Crew and Guidance"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"color": "--lightD1"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 0px 0px",
			"font": "--base",
			"color": "--lightD1",
			"children": <>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Qualified Pilots
					<br />
				</Strong>
				Our team members are seasoned sailors, ready to share their maritime knowledge and ensure a safe and enjoyable journey.
			</>
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 0px 0px",
			"font": "--base",
			"color": "--lightD1",
			"children": <>
				<Strong>
					Customized Routes.
				</Strong>
				{" "}Take advantage of our captains' expertise, who can design personalized routes showcasing the beauty and mystery of the ocean.
			</>
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center"
		}
	}
};

const Services = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" color="--light" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text2")} />
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Text {...override("text3")} />
					</Box>
					<Box {...override("box4")}>
						<Text {...override("text4")} />
					</Box>
					<Box {...override("box5")} />
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Text {...override("text5")} />
				<Box {...override("box7")}>
					<Box {...override("box8")}>
						<Text {...override("text6")} />
					</Box>
					<Box {...override("box9")}>
						<Text {...override("text7")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box10")}>
				<Text {...override("text8")} />
				<Box {...override("box11")}>
					<Box {...override("box12")}>
						<Text {...override("text9")} />
					</Box>
					<Box {...override("box13")}>
						<Text {...override("text10")} />
					</Box>
					<Box {...override("box14")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Services, { ...Section,
	defaultProps,
	overrides
});
export default Services;